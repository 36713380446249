<template>
	<v-container fluid style="max-width: 1400px">
		<div class="mx-2 my-3">
			<span class="text-h3">{{ $t('events.myEvents') }}</span>
		</div>

		<v-divider class="my-2" />

		<v-row>
			<v-col>
				<v-btn color="info" rounded :to="{ name: 'EventCreate' }" class="float-right">
					{{ $t('offers.create') }}
				</v-btn>
			</v-col>
		</v-row>

		<div class="d-flex align-center">
			<v-icon class="mx-4">
				mdi-magnify
			</v-icon>
			<v-text-field
				v-model="title"
				:placeholder="`${$t('search.search')}: ${$t('auth.name')}, ${$t('companies.company')}, ${$t('companies.location')}`"
			/>
			<v-dialog v-model="filtersEditMode" max-width="1000px" @click:outside="filtersSwitchEditMode()">
				<template v-slot:activator="{ on }">
					<v-btn color="secondary" v-on="on" elevation="2" rounded small text>
						<v-icon class="mx-4">
							mdi-filter
						</v-icon>
						{{ $t('search.filter') }}
					</v-btn>
				</template>
				<v-card rounded="xl">
					<v-card-title>
						<span class="headline">{{ $t('search.filters') }}</span>
						<v-spacer />
						<v-btn icon color="secondary" @click="filtersSwitchEditMode()">
							<v-icon class="mx-4">
								mdi-close
							</v-icon>
						</v-btn>
					</v-card-title>
					<v-card-text>
						<v-row>
							<v-col cols="12" md="3" sm="12">
								<v-select
									v-model="filters.status"
									:label="$t('applications.status')"
									:items="statusOptions"
									item-text="name"
									item-value="key"
									chips
									multiple
									return-object
									outlined
									rounded
									hide-details="auto"
									class="pb-0"
								/>
							</v-col>
							<v-col cols="12" md="9" sm="12">
								<v-text-field v-model="filters.location" :label="$t('companies.location')" outlined rounded hide-details="auto" class="pb-0" />
							</v-col>
							<v-col cols="12" md="6" sm="12">
								<v-select
									v-model="filters.eventTypes"
									:label="$t('events.eventType')"
									:items="availableEventTypes"
									item-text="name"
									item-value="id"
									chips
									deletable-chips
									multiple
									return-object
									outlined
									rounded
									hide-details="auto"
									class="pb-0"
								/>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>

		<v-fade-transition group hide-on-leave>
			<template v-if="loadingEvent">
				<v-skeleton-loader v-for="i in 5" :key="i" type="list-item-avatar-three-line" class="mx-auto mb-5 rounded-xl" max-width="1200" />
			</template>

			<template v-else-if="events.length">
				<template v-for="event in filteredEvents">
					<MyEventCard :key="event.id" :event="event" />
				</template>

				<template v-if="!filteredEvents.length">
					<h4 class="m-4 text-center" key="noResultsCriteria">
						{{ $t('search.noResultsCriteria') }}
					</h4>
				</template>
			</template>

			<template v-else>
				<div key="noItemsData" class="mx-auto">
					<div class="mt-2 mb-5 text-center">
						<v-icon size="128" role="img">
							mdi-package-variant
						</v-icon>
						<br />
						<span class="text-h6 font-weight-bold">{{ $t('search.empty') }}</span>
					</div>
					<h4 class="m-4 text-center">
						{{ $t('offers.myEventsHelp') }}
					</h4>
				</div>
			</template>
		</v-fade-transition>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

// generic accent removal from input string, add any missing characters
function removeAccents(input) {
	if (!input) return ''
	const letters1 = 'äáàâăëéèêĕíìüúùûŭöóòôŏÄÁÀÂĂËÉÈÊĔÜÚÙÛŬÖÓÒÔŎßșȘ'
	const letters2 = 'aaaaaeeeeeiiuuuuuoooooAAAAAEEEEEUUUUUOOOOOssS'
	const patternLetters = new RegExp('[' + letters1 + ']', 'g')
	const lookupLetters = {}
	letters1.split('').forEach(function(letter, i) {
		lookupLetters[letter] = letters2[i]
	})
	const letterTranslator = function(match) {
		return lookupLetters[match] || match
	}
	return input.replace(patternLetters, letterTranslator)
}

export default {
	metaInfo() {
		return {
			title: this.$t('events.myEvents')
		}
	},
	data() {
		return {
			filtersEditMode: false,
			title: '',
			statusOptions: [
				{ key: '0', name: this.$t('offers.canceled') },
				{ key: '1', name: this.$t('offers.active') },
				{ key: '2', name: this.$t('events.finished') }
			],
			filters: {
				status: [],
				eventTypes: [],
				location: ''
			}
		}
	},
	components: {
		MyEventCard: () => import('@/components/events/MyEventCard.vue')
	},
	computed: {
		filteredEvents() {
			return this.events.filter(
				(event) =>
					(!this.filters.eventTypes.length || this.filters.eventTypes.map((e) => e.id).includes(event.type.id)) &&
					(!this.filters.status.length || this.filters.status.map((e) => e.key).includes(event.status.value)) &&
					removeAccents(event.info.location)
						.toLowerCase()
						.includes(removeAccents(this.filters.location).toLowerCase()) &&
					(removeAccents(event.title)
						.toLowerCase()
						.includes(removeAccents(this.title).toLowerCase()) ||
						removeAccents(event.info.location)
							.toLowerCase()
							.includes(removeAccents(this.title).toLowerCase()) ||
						removeAccents(event.company.name)
							.toLowerCase()
							.includes(removeAccents(this.title).toLowerCase()) ||
						removeAccents(event.companyName)
							.toLowerCase()
							.includes(removeAccents(this.title).toLowerCase()))
			)
		},
		...mapGetters({
			company: 'companies/company',
			events: 'events/events',
			loadingEvent: 'events/loading',
			availableEventTypes: 'events/eventTypes'
		})
	},
	created() {
		this.setLoadingEvent(true)
		this.fetchEventTypes()
		this.fetchEvents().then(() => {
			this.setLoadingEvent(false)
		})
	},
	methods: {
		filtersSwitchEditMode() {
			this.filtersEditMode = !this.filtersEditMode
		},
		...mapActions('events', ['setLoadingEvent', 'fetchEvents', 'fetchEventTypes'])
	}
}
</script>
